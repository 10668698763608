import React from "react";
import { FaTimes } from 'react-icons/fa';

export default function ProjectCard({ project, onBack }) {
  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-full relative text-white">
      <button 
        onClick={onBack} 
        className="absolute top-2 right-2 text-red-500 hover:text-red-700 focus:outline-none"
      >
        <FaTimes size={20} /> {/* Using Font Awesome "X" icon */}
      </button>

      <div className="flex flex-col md:flex-row items-center">
        <div className="w-full md:w-1/2 h-auto md:mr-6 mb-4 md:mb-0">
          <img
            alt="gallery"
            className="object-contain object-center rounded-lg w-full h-64 sm:h-72 md:h-80 lg:h-96"
            src={project.image}
          />
        </div>
        <div className="flex-grow md:w-1/2 text-left">
          <h2 className="text-custom-accent text-sm font-medium mb-1">
            {project.subtitle}
          </h2>
          <h1 className="text-white text-lg font-medium mb-3">
            {project.title}
          </h1>
          <p className="leading-relaxed mb-4">{project.description}</p>
          <div className="flex space-x-4">
        <a
            href={project.gitlink}
            className="inline-flex items-center text-custom-accent border-2 border-custom-accent py-1 px-6 focus:outline-none hover:bg-[#64ffda] hover:text-black rounded text-lg"
            whileHover="hover"
            whileTap={{ scale: 0.95 }}
          > 
           Github
          </a>
      
            <a
              href={project.hostlink}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center text-gray-400 bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-base"
            >
              View Project
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
