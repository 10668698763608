import React from "react";
import { motion } from "framer-motion";
import { AiOutlineMail } from "react-icons/ai";
import Lottie from "lottie-react";
import rocketAnimation from "../lottie/rocket.json"; 

export default function About() {

  return (
    <motion.section
      id="about"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
      className="min-h-screen flex flex-col items-center justify-center bg-gray-800"
    >
      <div className="mb-0 h-40 w-40"> 
        <Lottie animationData={rocketAnimation} loop={true} />
      </div>
      <div className="container mx-auto flex px-10 py-5 flex-col items-center justify-center text-center font-ntr"> {/* Adjusted padding */}
        <motion.h1
          className="title-font text-3xl sm:text-4xl lg:text-5xl mb-4 font-bold text-white"
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.6 }}
        >
          Hi, I'm <span className="text-[#64ffda]">Connor</span>.
          <br className="hidden lg:inline-block" />
        </motion.h1>
        <motion.p
          className="text-lg sm:text-xl lg:text-2xl mb-8 leading-relaxed text-white"
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          I'm a software developer based in Vancouver, Canada.
        </motion.p>
        <div className="flex justify-center">
        <motion.a
            href="mailto:connorflynn4@gmail.com"
            className="inline-flex items-center text-custom-accent border-2 border-custom-accent py-2 px-6 focus:outline-none hover:bg-[#64ffda] hover:text-black rounded text-lg"
            whileHover="hover"
            whileTap={{ scale: 0.95 }}
          >
            <AiOutlineMail className="mr-2" />
            Say hello!
          </motion.a>
        </div>
      </div>
    </motion.section>
  );
}
