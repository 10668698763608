import React, { useState, useEffect } from "react";
import About from "./components/About";
import Navbar from "./components/Navbar";
import Profile from "./components/Profile";
import Projects from "./components/Projects";

export default function App() {
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = () => {
    const totalHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrollPosition = window.pageYOffset;
    const scrollPercentage = (scrollPosition / totalHeight) * 100;

    setScrollProgress(scrollPercentage);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main className="text-black-400 bg-white-900 body-font">
      <Navbar />
      <div
        className="h-1 bg-[#64ffda] fixed top-14 left-0 z-50 hidden sm:block"
        style={{ width: `${scrollProgress}%` }}
      ></div>
      <About />
      <Profile />
      <Projects />
      <footer className="w-full text-center py-12 bg-gray-800 text-white text-xs">
        Built and designed by Connor Flynn.
        <br />
        All rights reserved. ©
      </footer>
    </main>
  );
}
