import React, { useState } from "react";
import { AiFillLinkedin, AiFillGithub, AiOutlineDownload } from "react-icons/ai";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-gray-800 sticky top-0 z-10">
      <div className="container mx-auto flex flex-wrap p-5 flex-row items-center justify-between font-ntr">
        <div className="flex items-center">
          <a href="#about" className="title-font font-medium text-white text-xl">
            Connor Flynn
          </a>
          <nav className="hidden lg:flex flex-grow items-center text-white ml-4">
          <a href="#profile" className="mr-5 hover:text-custom-accent rounded">
              About
            </a>
            <a href="#projects" className="mr-5 hover:text-custom-accent rounded">
              Past Work
            </a>
          
          </nav>
        </div>
        <button
          className="inline-flex p-3 rounded lg:hidden text-white ml-auto hover:text-white outline-none"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
            ></path>
          </svg>
        </button>
        <div className={`${isOpen ? "block" : "hidden"} lg:flex lg:items-center w-full lg:w-auto mt-4 lg:mt-0`}>
          <nav className="flex flex-col lg:flex-row lg:items-center lg:space-x-4 text-white">
            <a href="#projects" className="block mt-4 lg:mt-0 lg:hidden mr-5 hover:text-custom-accent rounded">
              Past Work
            </a>
            <a href="#skills" className="block mt-4 lg:mt-0 lg:hidden mr-5 hover:text-custom-accent rounded">
              Skills
            </a>
            <a
              className="block mt-4 lg:mt-0 text-white font-medium text-xl mr-5 hover:text-custom-accent"
              href="https://www.linkedin.com/in/connor-flynn-6433a3a5"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillLinkedin />
            </a>
            <a
              className="block mt-4 lg:mt-0 text-white font-medium text-xl mr-5 hover:text-custom-accent"
              href="https://github.com/connorflynn4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillGithub />
            </a>
            <a
              href="./ConnorFlynn_Resume.pdf"
              download="ConnorFlynnResume.pdf"
              className="block mt-4 lg:mt-0 text-white font-medium text-xl inline-flex items-center hover:text-custom-accent"
            >
              Download Resume
              <AiOutlineDownload className="ml-1" />
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
}
